@import "./fonts";
@import "theme-variables";

@font-face {
  font-family: NUSXURI;
  src: url("~assets/fonts/TbcDin_0.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MTAVRULI;
  src: url("~assets/fonts/TbcDin_Mtavruli_Medium.otf");
}

@font-face {
  font-family: MTAVRULI-BOLD;
  src: url("~assets/fonts/TbcDin_Mtavruli_Bold.otf");
}

@font-face {
  font-family: NUSXURI-REGULAR;
  src: url("~assets/fonts/TbcDin_Nusxuri_Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: NUSXURI-BOLD;
  src: url("~assets/fonts/TbcDin_Nusxuri_Bold.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: NUSXURI-MEDIUM;
  src: url("~assets/fonts/TbcDin_Nusxuri_Medium.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: TBCX-Black;
  src: url("~assets/fonts/TBCX-Black.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: TBCX-Bold;
  src: url("~assets/fonts/TBCX-Bold.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: TBCX-Light;
  src: url("~assets/fonts/TBCX-Light.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: TBCX-Medium;
  src: url("~assets/fonts/TBCX-Medium.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: TBCX-Regular;
  src: url("~assets/fonts/TBCX-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

$nav-base-color: #4f4f4f;
$bg-content: #ebf2ff;
$color-blue: #0f62ac;
$color-yellow: #ffd200;
$color-gray: $nav-base-color;
$color-nb: #0b4280;

$radius: 0.9rem;

.text-white-mtavruli-32 {
  font-size: 2em;
  font-family: MTAVRULI;
  color: #fff;
}

.text-white-mtavruli-22 {
  font-size: 1.375em;
  font-family: MTAVRULI;
  color: #fff;
}

.text-white-mtavruli-16 {
  font-size: 1em;
  font-family: MTAVRULI;
  color: #fff;
}

.text-white-mtavruli-14 {
  font-size: 0.825em;
  font-family: MTAVRULI;
  color: #fff;
}

.text-white-mtavruli-12 {
  font-size: 0.75em;
  font-family: MTAVRULI;
  color: #fff;
}

.text-blue-mtavruli-32 {
  font-size: 2em;
  font-family: MTAVRULI;
  color: $color-blue;
}

.text-blue-mtavruli-22 {
  font-size: 1.375em;
  font-family: MTAVRULI;
  color: $color-blue;
}

.text-blue-mtavruli-16 {
  font-size: 1em;
  font-family: MTAVRULI;
  color: $color-blue;
}

.text-blue-mtavruli-14 {
  font-size: 0.875em;
  font-family: MTAVRULI;
  color: $color-blue;
}

.text-blue-mtavruli-12 {
  font-size: 0.75em;
  font-family: MTAVRULI;
  color: $color-blue;
}

.text-grey-mtavruli-32 {
  font-size: 2em;
  font-family: MTAVRULI;
  color: $nav-base-color;
}

.text-grey-mtavruli-22 {
  font-size: 1.375em;
  font-family: MTAVRULI;
  color: $nav-base-color;
}

.text-grey-mtavruli-16 {
  font-size: 1em;
  font-family: MTAVRULI;
  color: $nav-base-color;
}

.text-grey-nusxuri-16 {
  font-size: 1em;
  font-family: NUSXURI;
  color: $nav-base-color;
}

.text-grey-nusxuri-14 {
  font-size: 0.875em;
  font-family: NUSXURI;
  color: $nav-base-color;
}

.text-grey-nusxuri-12 {
  font-size: 0.75em;
  font-family: MTAVRULI;
  color: $nav-base-color;
}

.fa-color-blue {
  color: $color-blue;
}

.fa-size {
  font-size: 1em;
}

.blue-box {
  border-radius: $radius;
  border: solid 1px $color-blue;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: $color-blue;
}

.blue-box a {
  text-decoration: none;
  color: $color-blue;
}

.blue-box a:hover {
  text-decoration: none;
  color: $color-blue;
}

.blue-box a:visited {
  text-decoration: none;
  color: $color-blue;
}

.color-gray {
  color: $color-gray;
}

.nav-style {
  color: $nav-base-color;
  font-size: 1em;
  font-family: MTAVRULI;
}

@mixin mobile {
  @include media-breakpoint-up(sm) {
    @content;
  }
}

@mixin tablet {
  @include media-breakpoint-up(md) {
    @content;
  }
}

@mixin desktop {
  @include media-breakpoint-up(lg) {
    @content;
  }
}

.box-shade {
  -webkit-box-shadow: 0px 0px 29px 8px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 0px 29px 8px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 0px 29px 8px rgba(0, 0, 0, 0.18);
}

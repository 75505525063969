@import "src/theme/theme-variables";
@import "src/theme/theme";

.relative-position {
  position: relative;
}

.container-fluid {
  max-width: 1280px;
}

.main-product-item {
  border-radius: $radius;
  background-repeat: no-repeat;
  padding: 40px;
  height: 520px+30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.main-product-item:hover {
  -webkit-box-shadow: 0px 0px 29px 8px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 0px 29px 8px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 0px 29px 8px rgba(0, 0, 0, 0.18);
}

.main-product-item > h1 {
  color: #fff;
  @extend .text-white-mtavruli-32;
}

.main-product-item > .subheader {
  color: white;
  font-family: TBCX-Regular;
  font-size: 24px;
}

.product-item {
  border-radius: $radius;
  min-height: 260px;
  background-repeat: no-repeat;
  margin: 15px;
  padding: 40px;
}

.product-item:hover {
  @extend .box-shade;
}

.product-item > h1 {
  color: #fff;
  @extend .text-white-mtavruli-32;
}

.product-detail {
  position: absolute;
  bottom: 0;
  width: 70%;
  margin-bottom: 30px;
}

.product-detail .send-button {
  width: 216px;
}

.home-section {
  max-width: 1720px;
}

.home-section > h1 {
  color: #19457f;
  font-size: 36px;
  font-family: TBCX-Bold;
  font-feature-settings: "case" on;
  padding: 60px 0;
}

.home-section > h3 {
  display: inline-block;
  @extend .text-grey-mtavruli-22;
}

.agreements {
  margin-top: 48px;
}

.agreements-banner {
  width: 100%;
  border-radius: 12px;
}

.individual {
  max-width: 1720px;
  margin-bottom: 90px;
}

.individual > h1 {
  color: #4f4f4f;
  font-size: 32px;
  font-family: TBCX-Medium;
  font-feature-settings: "case" on;
  padding: 60px 0;
}

// =============================
// Submit form styling
// =============================

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 550px;
  margin: 15px auto;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  font-family: TBCX-Medium;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  border-radius: 14.4px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  padding: 32px 24px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

/* Style the back side */
.flip-card-back {
  background-color: #19457f;
  color: white;
  transform: rotateY(180deg);
}

.thank-you-block {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .thank-you-header {
    font-family: TBCX-Bold;
    font-size: 40px;
  }

  .description {
    font-size: 21px;
    margin-bottom: 22px;
  }

  p {
    margin: 0;
  }

  hr {
    opacity: 1;
    margin: 22px 12px 28px 12px;
  }

  .return-button {
    position: absolute;
    right: 18px;
    top: 16px;
    font-size: 20px;
    cursor: pointer;
  }
}

.form-header {
  text-align: left;
  font-size: 30px;
  margin-bottom: 32px;
  font-family: "TBCX-Bold";
  color: #19457f;
}

.send-button {
  border: none;
  text-align: center;
  background-color: #19457f;
  border-radius: 48px;
  padding: 16px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  font-family: "TBCX-MEDIUM";
}

.send-button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.submit-form {
  padding: 0 8px;

  label {
    display: block;
  }

  .info-input {
    width: 100%;
    text-align: left;
  }

  .info-input label {
    margin-bottom: 8px;
    color: black;
    font-family: TBCX-Medium;
    font-size: 15px;
  }

  .info-input input {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #dddddd;
    margin: 0 0 26px;
    padding: 0 16px;
    outline: none;
    height: 54px;
    font-family: TBCX-Medium;
  }

  input {
    margin-top: 15px;
    display: inline;
  }

  .info-input.prefix-input {
    position: relative;

    .input-prefix {
      position: absolute;
      top: 0;
      left: 0;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #dddddd;
      width: 64px;
    }

    input {
      padding-left: 80px;
    }
  }

  label {
    color: #062642;
  }

  .send-button {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 48px);
  }
}

.info-wrapper {
  font-family: TBCX-Medium;
  margin: 64px 0;
  .info-header {
    font-weight: bold;
    color: #19457f;
    font-size: 48px;
    padding: 0 12px;
  }

  hr {
    margin: 24px 0;
  }

  .info-description {
    font-size: 16px;
    padding: 0 12px;
  }
}

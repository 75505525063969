@import "src/theme/theme-variables";
@import "src/theme/theme";

.carousel-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 0;
  overflow: hidden;
}

.img-wrapper {
  width: 100%;
  height: 240px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 0;
  }

  @include mobile {
    height: 330px;
    img {
      object-position: 0 0;
    }
  }
}
.overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.3;
  z-index: 10;

  @include mobile {
    opacity: 0;
  }
}

.carousel-wrapper::ng-deep {
  .carousel-caption {
    font-family: TBCX-Medium;
    left: 20px;
    text-align: left;
    top: 0;
    max-width: calc(100% - 40px);
    z-index: 20;

    font-size: 14px;

    p {
      font-family: TBCX-Regular;
      margin-bottom: 32px;
      display: none;
    }

    h3 {
      font-size: 24px;
      font-family: TBCX-Black;
      margin-bottom: 32px;
    }

    button.more {
      background: #19457f;
      border: 0;
      border-radius: 20px;
      color: #ffffff;
      cursor: pointer;
      display: inline-block;
      font-family: TBCX-Medium;
      font-feature-settings: "case" on;
      font-size: 16px;
      font-weight: 500;
      line-height: 2.5;
      outline: transparent;
      padding: 0 1.5rem;
      text-align: center;
      text-decoration: none;
      transition: box-shadow 0.2s ease-in-out;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      white-space: nowrap;

      &:not([disabled]):focus {
        box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5), -0.125rem -0.125rem 1rem rgba(25, 69, 127, 0.5);
      }

      &:not([disabled]):hover {
        box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5), -0.125rem -0.125rem 1rem rgba(25, 69, 127, 0.5);
      }
    }

    @include mobile {
      left: 40px;
      top: 50%;
      transform: translateY(-50%);
      max-width: 70%;
      font-size: 16px;

      h3 {
        font-size: 28px;
        margin-bottom: 8px;
      }

      p {
        display: block;
      }

      button.more {
        font-size: 18px;
      }
    }
  }

  .carousel-item {
    display: block !important;
    z-index: 1;
    position: absolute;
    opacity: 0;
    transition: all 0.6s ease-in;
    visibility: hidden;
  }

  .carousel-item.active {
    position: relative;
    visibility: visible;
    opacity: 1;
    transition: all 0.6s ease-out;
  }

  .carousel-indicators li {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin: 10px;
    text-indent: 0;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    background-color: #c1c8d3;
    box-shadow: inset 1px 1px 1px 1px transparent;
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: 40px;
    width: 40px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    opacity: 1;
    transition: all 0.2s;

    top: auto;
    left: auto;
    bottom: 40px;

    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .carousel-control-prev {
    right: 70px;
    @include desktop {
      right: 120px;
    }
  }

  .carousel-control-next {
    right: 20px;

    @include desktop {
      right: 70px;
    }
  }

  .carousel-control-prev-icon {
    background-image: url("../../../assets/slides/chevron-left.svg") !important;
    // background-repeat: no-repeat;
  }

  .carousel-control-next-icon {
    background-image: url("../../../assets/slides/chevron-right.svg") !important;
    // background-repeat: no-repeat;
  }
}

.products-wrapper {
  width: 100%;
  padding: 24px 0 42px;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
}

.product-card {
  margin: 0 24px;
  background-color: #efeff0;
  color: #414141;

  flex: 0 0 260px;
  border-radius: 24px;
  min-width: 0;
  display: flex;
  flex-direction: column;
  padding: 24px 0;
}

.product-card.blue-card {
  background-color: #397bdf;
  color: white;

  button.details {
    background-color: white;
    a {
      color: #397bdf;
    }
  }
}

.product-card.yellow-card {
  background-color: #fef6e8;
}

.product-card.light-blue-card {
  background-color: #e5e8f2;
}

.product-image {
  flex: 0 0 140px;
  min-height: 0;
  object-fit: contain;
  margin-bottom: 12px;
}

.product-name {
  font-family: TBCX-Bold;
  font-feature-settings: "case" on;
  text-align: center;
  font-size: 26px;
  flex: 0 0 72px;
}

.product-description {
  font-family: TBCX-Regular;
  text-align: center;
  font-size: 12px;
  padding: 12px 12px;
}

.spacer {
  flex: 1;
}

button.details {
  background: #19457f;
  border: 0;
  border-radius: 20px;
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-family: TBCX-Medium;
  font-feature-settings: "case" on;
  font-size: 18px;
  font-weight: 500;
  outline: transparent;
  padding: 2px 16px;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  margin: 0 auto;

  &:not([disabled]):focus {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5), -0.125rem -0.125rem 1rem rgba(25, 69, 127, 0.5);
  }

  &:not([disabled]):hover {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5), -0.125rem -0.125rem 1rem rgba(25, 69, 127, 0.5);
  }
}

button.details a {
  color: white;
  text-decoration: none;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #19457f;
}

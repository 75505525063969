body {
  background-color: #ebf2ff;
}

.tbcl-cookie-body {
  position: fixed;
  border-radius: 10px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  background-color: rgba(15, 67, 127, 0.95);
  color: white;
  z-index: 1000;
}

.tbcl-cookie-wrapper {
  position: relative;
  padding: 20px;
}

.fas {
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: 14px;
  cursor: pointer;
}

.tbcl-cookie-text {
  margin-bottom: 0;
  font-size: 14px;
}

.tbcl-cookie-text i {
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  font-style: normal;
  color: #efb10f;
}

.tbcl-cookie-text a {
  cursor: pointer;
  font-weight: bold;
  font-style: normal;
  color: #efb10f;
  text-decoration: none;

  &:hover {
    color: #efb10f;
    text-decoration: none;
  }
}

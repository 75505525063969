@import "src/theme/theme-variables";
@import "src/theme/theme";

.tbcl-nav {
  width: 100%;
  padding: 0;
  background-color: white;
  position: relative;
  z-index: 100;
}

.top-header {
  height: 80px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  .toggle-menu {
    visibility: visible;
    display: inline;
    cursor: pointer;
    padding: 8px;
    margin-left: 12px;

    @include desktop {
      visibility: hidden;
      display: none;
    }
  }

  .contact-image {
    display: block;
    height: 38px;
    width: 136px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    @include desktop {
      height: 50px;
      width: 180px;
    }
  }

  .logo-wrapper {
    display: flex;
  }

  .tbcl-logo {
    background-image: url("../../../assets/tbcleasing-logo-mobile.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 128px;
    height: 74px;

    img {
      display: none;
      height: 100%;
      object-fit: contain;
    }

    @include desktop {
      background-image: none;
      width: 200px;
      height: 40px;

      img {
        display: block;
      }
    }
  }

  .language-toggle {
    display: none;
    margin-right: 24px;
    height: 40px;
    width: 40px;
    object-fit: contain;
    cursor: pointer;

    @include desktop {
      display: block;
    }
  }
}

.bottom-header {
  position: relative;
  background-color: #19457f;
  font-family: TBCX-Medium;
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

  &.expanded {
    max-height: 1200px;
    transition: max-height 1s ease-in-out;
  }

  @include desktop {
    max-height: none !important;
  }

  .bottom-navigation {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
  }

  .navigation-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      padding: 12px 12px 12px 18px;
      color: white;
      position: relative;
      cursor: pointer;
      text-wrap: nowrap;
      font-size: 12px;
    }

    .navigation-link {
      color: inherit;
      text-decoration: none;
    }

    .navigation-link::before {
      content: "";
      width: 0;
      height: 0;

      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;

      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    .navigation-link.active::before,
    .navigation-link:hover::before {
      border-left-color: white;
    }

    @include desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        padding: 16px 12px;
        font-size: 16px;
      }

      .navigation-link::before {
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid transparent;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      .navigation-link.active::before,
      .navigation-link:hover::before {
        border-top-color: white;
        border-left-color: transparent;
      }
    }
  }
}

.overlay {
  @include desktop {
    visibility: visible;
  }
  visibility: hidden;

  opacity: 0;
  transition: 0.2s all ease-in;

  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5);
}

.pop-down {
  @include desktop {
    visibility: visible;
  }
  visibility: hidden;
  pointer-events: none;
  // display: none;
  opacity: 0;
  transition: 0.2s opacity ease-in;

  font-family: TBCX-Medium;
  position: absolute;
  z-index: 100;
  top: 100%;
  background-color: red;
  width: 100%;
  max-width: 1280px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 12px 12px;
  background-color: white;

  .wrapper {
    padding: 32px 72px;
  }

  h1 {
    font-family: TBCX-BOLD;
    font-size: 32px;
    padding: 0 12px;
    margin-bottom: 32px;
  }

  .list-item {
    display: block;
    padding: 8px 12px;
    color: black;
    transition: 0.3s all ease-in;
    text-decoration: none;
  }

  .list-item:hover {
    color: rgba(0, 0, 0, 0.9);
    text-decoration: none;
    transform: translateX(32px);
  }
}

.drop-arrow {
  font-size: 10px;
  margin-left: 8px;
  visibility: hidden;
  @include desktop {
    visibility: visible;
  }
}

.pop-down.active,
.overlay.active {
  // display: block;
  pointer-events: auto;
  opacity: 1;
}

.location-icon {
  background-image: url("/assets/home/location.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  height: 30px;
  width: 30px;
  margin-right: 12px;
  @include desktop {
    height: 40px;
    width: 40px;
    margin-right: 24px;
  }
}

.icons-wrapper {
  display: flex;
  align-items: center;
}

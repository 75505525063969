@import "../../../theme/theme";

.footer-hr {
  border-top: solid 1px #e0e0e0;
}

.footer {
  max-width: 1720px;
}

.footer-height {
  min-height: 90px;
  @include desktop {
    min-height: 134px;
  }
}

.footer a {
  //@extend .nav-style;
  color: #a6a6a6;
  text-decoration: none;
  @extend .text-grey-nusxuri-12;

  @include desktop {
    font-size: 0.875em;
    font-family: NUSXURI;
    color: $nav-base-color;
  }
}

.footer-logo {
  width: 128px;
  height: 22px;

  img {
    height: 100%;
    object-fit: contain;
  }

  @include desktop {
    height: 40px;
  }
}
